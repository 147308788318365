var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-2"},[_c('b-row',{class:{ 'd-none': _vm.hideOptions },attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"cols":"12","md":"4"}},[(_vm.searchInput.visiable)?_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"clearable":true,"placeholder":_vm.$t('search')},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1):_vm._e()]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-end mb-1 mb-md-0",attrs:{"cols":"12","md":"2"}},[(_vm.createButton.visiable)?_c('b-button',{attrs:{"variant":"primary","data-action-type":"new"},on:{"click":function (v) {
              _vm.$emit('on-create', v);
            }}},[_vm._v(" "+_vm._s(_vm.createButton.text ? _vm.$t(_vm.createButton.text) : _vm.$t('new'))+" ")]):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',_vm._b({ref:"gtable",staticClass:"position-relative",attrs:{"id":"mainTable","items":_vm.items,"fields":_vm.columns,"primary-key":"id","show-empty":"","striped":"","hover":"","responsive":"","per-page":_vm.perPage,"filter-debounce":"700","current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.isSortDirDesc,"sort-direction":_vm.sortDirection,"filter":_vm.searchQuery,"filter-included-fields":_vm.filterOn,"busy":_vm.isBusy,"empty-text":_vm.$t('noMatchingRecordsFound')},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event},"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"head(actions)",fn:function(){return [_c('span')]},proxy:true},{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:(field.style)})})}},_vm._l((_vm.columns),function(field){return {key:("cell(" + (field.key) + ")"),fn:function(ref){
            var value = ref.value;
return [(field.isLocale)?_c('span',{key:field.key},[_vm._v(" "+_vm._s(field.isLocale ? _vm.$t(value) : value)+" ")]):(field.type === 'number')?_c('span',{key:field.key},[_vm._v(" "+_vm._s(_vm._f("fraction")(value,'number', _vm.currentBranch.decimalDigits))+" ")]):_c('span',{key:field.key,domProps:{"innerHTML":_vm._s(value)}})]}}}),{key:"cell(actions)",fn:function(data){return [_vm._t("actions",null,{"item":data.item}),(!_vm.slots.actions)?_c('div',{staticClass:"text-nowrap"},[(_vm.editButton.visiable)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t(_vm.editButton.text || 'edit')),expression:"$t(editButton.text || 'edit')",modifiers:{"hover":true,"top":true}}],attrs:{"variant":"flat-primary","small":"","size":"sm"},on:{"click":function () { if (_vm.editButton.handler) { _vm.editButton.handler(data.item); } }}},[_c('feather-icon',{staticClass:"clickable",attrs:{"id":("invoice-row-" + (data.item.id) + "-preview-icon"),"icon":_vm.editButton.icon || 'EditIcon'}})],1):_vm._e(),(_vm.deleteButton.visiable)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t(_vm.deleteButton.text || 'edit')),expression:"$t(deleteButton.text || 'edit')",modifiers:{"hover":true,"top":true}}],attrs:{"variant":"flat-danger","small":"","size":"sm"},on:{"click":function () { if (_vm.deleteButton.handler) { _vm.deleteButton.handler(data.item); } }}},[_c('feather-icon',{staticClass:"clickable danger",attrs:{"icon":_vm.deleteButton.icon || 'TrashIcon',"stroke":"red","id":("invoice-row-" + (data.item.id) + "-delete-icon")}})],1):_vm._e()],1):_vm._e()]}},(this.$route.name === 'history')?{key:"row-details",fn:function(data){return [_c('b-card',[_c('b-table',{ref:"detailsTable",attrs:{"items":data.item.changeSetEntries,"fields":_vm.detailsColumns},scopedSlots:_vm._u([{key:"head(actions)",fn:function(){return [_c('span')]},proxy:true},{key:"cell(transactionType)",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"badge badge-primary"},[_vm._v(_vm._s(item.transName))])]}},{key:"cell(operation)",fn:function(ref){
            var item = ref.item;
return [(item.operation === 'added')?_c('span',{staticClass:"badge badge-primary"},[_vm._v(_vm._s(_vm.$t('added')))]):_vm._e(),(item.operation === 'deleted')?_c('span',{staticClass:"badge badge-danger"},[_vm._v(_vm._s(_vm.$t('delete')))]):_vm._e(),(item.operation === 'modified')?_c('span',{staticClass:"badge badge-warning"},[_vm._v(_vm._s(_vm.$t('modified')))]):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
            var detailsShowing = ref.detailsShowing;
            var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[(item.changeSetModifiedProperties !== null && item.changeSetModifiedProperties.length)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(detailsShowing ? _vm.$t('hide')+' '+_vm.$t('itemDetails') : _vm.$t('show')+' '+_vm.$t('itemDetails')),expression:"detailsShowing ? $t('hide')+' '+$t('itemDetails') : $t('show')+' '+$t('itemDetails')",modifiers:{"hover":true,"top":true}}],attrs:{"variant":"flat-warning","size":"sm"},on:{"click":function($event){return _vm.toggleDetails(item)}}},[_c('feather-icon',{staticClass:"mx-0 clickable warning",attrs:{"icon":"InfoIcon","id":("invoice-row-" + (item.id) + "-info-icon")}})],1):_vm._e()],1)]}},{key:"row-details",fn:function(ref){
            var item = ref.item;
return [_c('b-card',{staticClass:"mb-0 bg-light-primary"},[_c('b-table',{ref:"detailsTable",attrs:{"items":item.changeSetModifiedProperties,"fields":_vm.methodDetailsColumns}})],1)]}}],null,true)})],1)]}}:null,_vm._l((_vm.columns),function(field){return {key:("foot(" + (field.key) + ")"),fn:function(){return [_vm._v(" "+_vm._s(typeof field.footer === 'function' ? field.footer() : '')+" ")]},proxy:true}})],null,true)},'b-table',_vm.$attrs,false))],1)],1),(!_vm.hideOptions)?_c('b-card-body',{staticClass:"d-flex justify-content-between flex-wrap pt-0"},[_c('b-form-group',{staticClass:"text-nowrap mb-md-0 mr-1",attrs:{"label":_vm.$t('entries'),"label-cols":"6","label-align":"left","label-size":"sm","label-for":"sortBySelect"}},[_c('b-form-select',{attrs:{"id":"perPageSelect","size":"sm","inline":"","options":_vm.perPageOptions},on:{"change":function (v) {
          if (_vm.perPage === 'الكل') {
            _vm.perPage = 10000
          }
          }},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('div',[_c('b-pagination',{staticClass:"mb-0",attrs:{"total-rows":_vm.totalRows || _vm.items.length,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }